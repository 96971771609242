@import '~less/mixins';
@import '~less/variables';

/* ==========================================================================
   Grid
   ========================================================================== */

.container {
  .centered();
  width: @container-desktop;
}

.wide-container {
  .centered();
  width: @wide-container-desktop;
}

.row {
  .clearfix();
  margin-left: (@grid-gutter-width / -2);
  margin-right: (@grid-gutter-width / -2);
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
  padding-left: (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
}

.col-1 {
  width: percentage((1 / @grid-columns));
}
.col-2 {
  width: percentage((2 / @grid-columns));
}
.col-3 {
  width: percentage((3 / @grid-columns));
}
.col-4 {
  width: percentage((4 / @grid-columns));
}
.col-5 {
  width: percentage((5 / @grid-columns));
}
.col-6 {
  width: percentage((6 / @grid-columns));
}
.col-7 {
  width: percentage((7 / @grid-columns));
}
.col-8 {
  width: percentage((8 / @grid-columns));
}
.col-9 {
  width: percentage((9 / @grid-columns));
}
.col-10 {
  width: percentage((10 / @grid-columns));
}
.col-11 {
  width: percentage((11 / @grid-columns));
}
.col-12 {
  width: 100%;
}
