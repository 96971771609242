/* ==========================================================================
   Mixins
   ========================================================================== */
/**
 * Clearfix
 * Source: http://nicolasgallagher.com/micro-clearfix-hack/
 *
 * For modern browsers
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
/* Container
   ========================================================================== */
/* Grid
   ========================================================================== */
/* ==========================================================================
   Grid
   ========================================================================== */
.container {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}
.container:before,
.container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.container:after {
  clear: both;
}
.wide-container {
  margin-left: auto;
  margin-right: auto;
  width: 1310px;
}
.wide-container:before,
.wide-container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.wide-container:after {
  clear: both;
}
.row {
  margin-left: -24px;
  margin-right: -24px;
}
.row:before,
.row:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.row:after {
  clear: both;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
  padding-left: 24px;
  padding-right: 24px;
}
.col-1 {
  width: 8.33333333%;
}
.col-2 {
  width: 16.66666667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333333%;
}
.col-5 {
  width: 41.66666667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333333%;
}
.col-8 {
  width: 66.66666667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333333%;
}
.col-11 {
  width: 91.66666667%;
}
.col-12 {
  width: 100%;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Black.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Bold.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Light.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Regular.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Semibold.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Thin.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Ultra.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Ultra.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Black_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Black_Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Bold_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Bold_Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Light_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Light_Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Semibold_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Semibold_Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Thin_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Thin_Italic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Display';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Ultra_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Display-Ultra_Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Black.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Black_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Black_Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Bold.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Bold_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Bold_Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Light.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Light_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Light_Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Regular.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Semibold.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Semibold_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Semibold_Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Thin.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Charlie Text';
  src: url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Thin_Italic.woff2") format("woff2"), url("../../../amkt-common/dist/styles/fontfaces/CharlieSans/Charlie_Text-Thin_Italic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
body {
  color: var(--ds-text, #172B4D);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ds-text, #253858);
  font-family: 'Charlie Display', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  font-weight: 600;
}
h1 {
  font-size: 52px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 24px;
}
h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 24px;
}
h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 32px;
  margin-bottom: 24px;
}
h4 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
}
h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
  letter-spacing: 0.3px;
}
h6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
a {
  color: var(--ds-link, #0052CC);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: var(--ds-link, #0065FF);
}
a:active {
  color: var(--ds-link-pressed, #0747A6);
}
a:hover,
a:active {
  outline: 0;
  text-decoration: underline;
}
