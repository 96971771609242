@import "~@atlaskit/util-shared-styles/src/colors";
@import "~@atlaskit/util-shared-styles/src/fonts";

:local(.footerContent) {
  background: @ak-color-N20;
  border-bottom: 2px solid @ak-color-N50;

  .footerContentInner {
    display: flex;
    justify-content: space-around;
    margin: auto;
    max-width: 980px;
    padding: 40px 0 10px;

    .footerSectionContainer {
      width: 33%;

      .mediaLinksContainer {

        .mediaLink {
          + .mediaLink {
            margin-left: 12px;
          }
        }
      }
    }
  }	
}