@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Black.woff2") format("woff2"), url("./Charlie_Display-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Bold.woff2") format("woff2"), url("./Charlie_Display-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Light.woff2") format("woff2"), url("./Charlie_Display-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Regular.woff2") format("woff2"), url("./Charlie_Display-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src:  url("./Charlie_Display-Semibold.woff2") format("woff2"), url("./Charlie_Display-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Thin.woff2") format("woff2"), url("./Charlie_Display-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Ultra.woff2") format("woff2"), url("./Charlie_Display-Ultra.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Black_Italic.woff2") format("woff2"), url("./Charlie_Display-Black_Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Bold_Italic.woff2") format("woff2"), url("./Charlie_Display-Bold_Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Italic.woff2") format("woff2"), url("./Charlie_Display-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Light_Italic.woff2") format("woff2"), url("./Charlie_Display-Light_Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Semibold_Italic.woff2") format("woff2"), url("./Charlie_Display-Semibold_Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Thin_Italic.woff2") format("woff2"), url("./Charlie_Display-Thin_Italic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Display';
  src: url("./Charlie_Display-Ultra_Italic.woff2") format("woff2"), url("./Charlie_Display-Ultra_Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Black.woff2") format("woff2"), url("./Charlie_Text-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Black_Italic.woff2") format("woff2"), url("./Charlie_Text-Black_Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Bold.woff2") format("woff2"), url("./Charlie_Text-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Bold_Italic.woff2") format("woff2"), url("./Charlie_Text-Bold_Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Italic.woff2") format("woff2"), url("./Charlie_Text-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Light.woff2") format("woff2"), url("./Charlie_Text-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Light_Italic.woff2") format("woff2"), url("./Charlie_Text-Light_Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Regular.woff2") format("woff2"), url("./Charlie_Text-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Semibold.woff2") format("woff2"), url("./Charlie_Text-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Semibold_Italic.woff2") format("woff2"), url("./Charlie_Text-Semibold_Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Thin.woff2") format("woff2"), url("./Charlie_Text-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charlie Text';
  src: url("./Charlie_Text-Thin_Italic.woff2") format("woff2"), url("./Charlie_Text-Thin_Italic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
