@import '~@atlaskit/util-shared-styles/src/fonts.less';
@import '~@atlaskit/util-shared-styles/src/mixins/headings.less';
@import '~@atlaskit/util-shared-styles/src/colors.less';

@import './fontfaces/CharlieSans/CharlieSans';

body {
  color: var(--ds-text, @ak-color-N800);
  font-family: @ak-font-family;
  font-size: @ak-font-size-default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ds-text, @ak-color-N700);
  font-family: 'Charlie Display', @ak-font-family;
  letter-spacing: 0; // resets -0.01em set by atlaskit css reset
  font-weight: 600;
}

h1 {
  font-size: 52px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 24px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 24px;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: .3px;
  line-height: 32px;
  margin-bottom: 24px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
}

h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
  letter-spacing: .3px;
}

h6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

a {
  color: var(--ds-link, @ak-color-B400);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--ds-link, @ak-color-B300);
  }

  &:active {
    color: var(--ds-link-pressed, @ak-color-B500);
  }

  &:hover,
  &:active {
    outline: 0;
    text-decoration: underline;
  }
}